@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@import 'primereact/resources/themes/nova/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

:root {
  /* Colors */
  --color-bg: #0b1b30;
  --color-border: #eeeff0;
  --color-button-next: #00e2b3;
  --color-progress-bar: #8473db;
  --color-button-submit: #e74545;
  --color-bg-button-previous: #f3f3f3;
  --color-button-previous: #6c7280;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
  overflow: overlay;
}

h2 {
  color: white;
  font-size: 18px;
}

h3 {
  color: var(--color-progress-bar);
}

label {
  font-weight: 500;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b1b1b1;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #b1b1b1;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #b1b1b1;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #b1b1b1;
}

#next-button {
  border-color: var(--color-button-next);
  background-color: var(--color-button-next);
}

#previous-button {
  border-color: var(--color-bg-button-previous);
  color: var(--color-button-previous);
  background-color: var(--color-bg-button-previous);
}

#submit-button {
  border-color: var(--color-button-submit);
  background-color: var(--color-button-submit);
}

#image-error {
  width: 60%;
}

#image-loading {
  width: 280px;
  height: 365px;
}

.main {
  margin-top: 50px;
  height: calc(100vh - 50px);
  max-width: 1400px;
  position: relative;
}

.loading-wrapper {
  background-color: var(--color-bg);
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 140px;
  height: 40px;
  font-size: 1.2rem;
  font-weight: 500;
}


.navbar {
  background: var(--color-bg);
  position: fixed;
  z-index: 1;
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden;
  height: 50px;
}

.sidebar {
  margin-top: 50px;
  background: var(--color-bg);
  height: calc(100vh - 50px);
  position: fixed;
  z-index: 1;
  top: 0; /* Stay at the top */
  left: 0;
}

.progress {
  color: var(--color-border);
  font-size: 12px;
  font-weight: 400;
}

.submit-confirmation {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.submit-confirmation h1 {
  color: var(--color-border);
  font-size: 38px;
}

.submit-confirmation h3 {
  font-size: 28px;
}

.validation-error {
  position: absolute;
}

.button-container {
  width: 320px;
  margin-right: 20px;
}

.p-component {
  font-family: 'Roboto';
}

.p-inputtext {
  border-radius: 0px;
  border-color: var(--color-border);
  border-width: 2px;
}

.input-number {
  border-radius: 0px;
  border-color: var(--color-border);
  border-width: 2px;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1rem;
  padding: 0.75rem 0.75rem;
}

input[type=number] {
  font-size: 1rem;
  padding: 0.75rem 0.75rem;
}

input[type=number]:focus {
  border-color: #34c4fd;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #b1b1b1;
}

.p-card {
  border-radius: 0px;
  border: 2px solid var(--color-border);
  box-shadow: none;
}

.p-progressbar {
  border-radius: 50px;
  height: 20px;
}

.p-progressbar .p-progressbar-label {
  display: none;
}
.p-progressbar .p-progressbar-value {
  border-radius: 50px;
  border: 0 none;
  margin: 0;
  background: var(--color-progress-bar);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--color-progress-bar);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: white;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: var(--color-progress-bar);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--color-progress-bar);
  background: var(--color-progress-bar);
  outline: var(--color-progress-bar);
}

.p-multiselect {
  border-radius: 0px;
  border-color: var(--color-border);
  border-width: 2px;
  font-size: 1rem;
  padding: 0.35rem 0.35rem;
}

.p-fieldset {
  border-radius: 0px;
  border-color: var(--color-border);
  border-width: 2px;
}

.p-dropdown {
  border-radius: 0px;
  border-color: var(--color-border);
  border-width: 2px;
  height: 2.9rem;
}

.p-multiselect.p-invalid.p-component {
  border-color: var(--color-button-submit);
}

.p-dropdown.p-invalid.p-component {
  border-color: var(--color-button-submit);
}

.p-inputtext.p-invalid.p-component {
  border-color: var(--color-button-submit);
}

.p-fieldset.p-invalid.p-component {
  border-color: var(--color-button-submit);
}

.p-invalid {
  border-color: var(--color-button-submit);
}
